.all {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px);
  .details {
    color: #096dd9;
    cursor: pointer;
  }
}
